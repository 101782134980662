<template>
    <section class="productos-desconocidos">
        <div v-if="cedisNavbar.length == 1" class="row mx-0 py-3 bg-white br-8">
            <div class="col-4 pr-0">
                <el-select
                v-model="id_producto"
                filterable
                remote
                reserve-keyword
                placeholder="Buscar producto"
                :remote-method="buscar_productos"
                size="small"
                class="w-100 br-20"
                @change="seleccionar_producto"
                >
                    <el-option
                    v-for="item in listado_productos"
                    :key="item.id"
                    :label="`${item.sku} - ${item.nombre} - ${item.presentacion}`"
                    :value="item.id"
                    />
                </el-select>
                <div class="row mx-0 mt-2">
                    <template v-if="id_producto">
                        <p class="col text-general2 text-center f-16">
                            <b class="text-general">{{ cantidad_solicitada }}</b> <br />
                            Solicitado
                        </p>
                        <p class="col text-general2 text-center f-16">
                            <b class="text-general">{{ parseFloat(producto.en_stock) }}</b> <br />
                            En stock
                        </p>
                    </template>
                </div>
            </div>
            <div class="col px-0">
                <div class="row mx-0">
                    <div v-show="id_producto" class="col-auto">
                        <img :src="producto.imagen" width="89" height="89" class="obj-cover br-10" />
                    </div>
                    <div v-show="id_producto" class="col px-0">
                        <p class="text-general f-500 nombre">
                            {{ producto.sku }} - {{ producto.nombre }}
                        </p>
                        <div class="row mx-0 align-items-center">
                            <p v-if="producto.promocion" class="col-auto px-0 f-600 f-14 text-general">
                                {{ convertMoney(producto.data_promocion.promo_valor, producto.idm_moneda) }}
                            </p>
                            <p v-else class="col-auto px-0 f-600 f-14 text-general">
                                {{ convertMoney(producto.precio, producto.idm_moneda) }}
                            </p>
                            <p v-show="producto.promocion" class="col-auto descuento text-gris f-600">
                                {{ convertMoney(producto.precio, producto.idm_moneda) }}
                            </p>
                        </div>
                        <p class="text-general2 f-13">
                            {{ producto.presentacion }}
                        </p>
                        <div v-if="producto.promocion" class="row mx-0">
                            <div class="bg-general px-3 f-12 text-white br-10">
                                {{ producto.data_promocion.texto }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row mx-0 py-2 justify-center text-general-red bg-whitesmoke2 br-10 my-3">
            Por favor seleccione un centro de distribución para realizar la busqueda.
        </div>
        <div class="row mx-0 mt-2">
            <div class="col-12 px-0">
                <el-table
                :data="vigentes"
                header-row-class-name="text-general"
                stripe
                class="cr-pointer"
                style="width:100%;"
                @row-click="seleccionar_pedido"
                >
                    <el-table-column align="center" min-width="95" label="Pedido">
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <div v-show="pedido_actual == row.id" class="bg-general br-10" style="width:8px;height:39px;" />
                                <p class="col cr-pointer d-middle text-general f-600 text-center">
                                    {{ row.id }}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="tendero" :label="`${$config.vendedor}`" min-width="180" />

                    <el-table-column prop="cantidad_pedida" label="Unidades">
                        <template slot-scope="{row}">
                            <p> {{ agregarSeparadoresNumero(row.cantidad_pedida,2) }}</p>
                        </template>
                    </el-table-column>

                    <el-table-column prop="leechero" label="Valor pedido" align="center" min-width="100">
                        <template slot-scope="{row}">
                            <p class="text-general f-600 text-center">
                                {{ convertMoney(row.valor_final,row.idm_moneda) }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column label="Estado" align="center" min-width="120">
                        <template slot-scope="{row}">
                            <p> {{ fun_estado(row.estado) }}</p><p />
                        </template>
                    </el-table-column>

                    <el-table-column label="Entrega" align="center">
                        <template slot-scope="{row}">
                            <p> {{ row.entrega_fecha | helper-fecha('DD MMM') }}</p>
                            <p> {{ row.entrega_horario + ':00 Hrs' }}</p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div v-show="id_producto" class="row mx-0 align-items-center mt-4 bg-white br-t-8 py-2 px-2">
            <div class="col-auto px-0">
                <p class="text-general f-18 pl-3 f-500">
                    Pedidos entregados
                </p>
            </div>
            <div class="col-auto pl-0 pr-2 ml-auto">
                Busca por fecha
            </div>
            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-5 pr-0">
                <el-date-picker
                v-model="rango_fechas"
                format="dd-MMM-yyyy"
                value-format="yyyy-MM-dd"
                type="daterange"
                class="w-100 br-20"
                size="small"
                range-separator="-"
                start-placeholder="Inicio"
                end-placeholder="Fin"
                />
            </div>
            <div class="btn-general bg-general text-white px-3 cr-pointer py-1 br-8 ml-2" @click="filtrar_fianalizados">
                Buscar
            </div>
        </div>
        <div v-show="id_producto" class="row mx-0">
            <div class="col-12 px-0">
                <el-table
                :data="finalizados"
                header-row-class-name="text-general"
                stripe
                style="width:100%;"
                @row-click="seleccionar_pedido"
                >
                    <el-table-column align="center" min-width="95" label="Pedido Entregado">
                        <template slot-scope="{row}">
                            <div class="row mx-0">
                                <div v-show="pedido_actual == row.id" class="bg-general br-10" style="width:8px;height:39px;" />
                                <p class="col cr-pointer d-middle text-general f-600 text-center">
                                    {{ row.id }}
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="tendero" label="Vendedor" min-width="180" />

                    <el-table-column prop="cantidad_pedida" label="Unidades">
                        <template slot-scope="{row}">
                            <p> {{ agregarSeparadoresNumero(row.cantidad_pedida,2) }}</p>
                        </template>
                    </el-table-column>

                    <el-table-column label="Valor pedido" align="center" min-width="100">
                        <template slot-scope="{row}">
                            <p class="text-general f-600 text-center">
                                {{ convertMoney(row.valor_final,row.idm_moneda) }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column label="Estado" align="center" min-width="120">
                        <template slot-scope="{row}">
                            <p> {{ fun_estado(row.estado) }}</p><p />
                        </template>
                    </el-table-column>

                    <el-table-column label="Entregado" align="center">
                        <template slot-scope="{row}">
                            <div v-if="row.estado == 4">
                                <p> {{ row.entrega_fecha | helper-fecha('DD MMM') }}</p>
                                <p> {{ row.entrega_horario + ':00 Hrs' }}</p>
                            </div>
                            <div v-else>
                                --
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </section>
</template>

<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import Pedidos from '~/services/pedidos/pedidos-admin'
export default {
    data(){
        return {
            buscar: '',
            rango_fechas:[
                moment().subtract(3,'days').format('YYYY-MM-DD'),
                moment().format('YYYY-MM-DD')
            ],

            vigentes:[],
            finalizados:[],

            listado_productos:[],
            producto:{},
            id_producto:null,
            pedido_actual:null
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            cedisNavbar:'cedis/cedisNavbar',
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
        }),
        cantidad_solicitada(){
            return _.sumBy(this.vigentes, function(o){
                return parseFloat(o.cantidad_pedida);
            });
        }

    },
    watch:{
        id_cedis(val){
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
            this.pedido_actual = -1
            this.id_producto = null
            this.vigentes = []
            this.finalizados =[]
            this.listado_productos = []
            this.buscar = ''
        }

    },
    destroyed(){
        this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
        this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
        this.$store.commit('pedidos/pedidos_admin/set_productos', [])
    },
    methods:{
        seleccionar_pedido(row){
            this.pedido_actual = row.id
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', row.id)
        },
        seleccionar_producto(id){
            this.$store.commit('pedidos/pedidos_admin/set_id_pedido', null)
            this.$store.commit('pedidos/pedidos_admin/set_pedido', {})
            this.$store.commit('pedidos/pedidos_admin/set_productos', [])
            this.pedido_actual = -1

            let producto = this.listado_productos.find(o=>o.id === id)
            this.id_producto = id
            this.producto = producto

            this.buscar_pedidos()
        },
        async buscar_pedidos(){
            try {
                let params = {
                    id_cedis:this.id_cedis,
                    id_producto:this.id_producto
                }
                const {data} = await Pedidos.listar_pedidos_x_producto(params)
                this.vigentes = data.vigentes
                this.finalizados = data.finalizados

            } catch (e){
                this.error_catch(e)
            }
        },
        async filtrar_fianalizados(){
            try {
                let params = {
                    id_cedis:this.id_cedis,
                    id_producto:this.id_producto,
                    fecha_inicio:this.rango_fechas[0],
                    fecha_fin:this.rango_fechas[1],
                }
                const {data} = await Pedidos.listar_pedidos_finalizados_x_producto(params)
                this.finalizados = data.finalizados

            } catch (e){
                this.error_catch(e)
            }
        },
        async buscar_productos(query){
            if(query.length > 2){
                const busqueda = async() => {
                    let params = {
                        id_cedis:this.cedisNavbar[0].id,
                        query
                    }
                    const {data} = await Pedidos.buscar_productos_x_cedis(params)
                    this.listado_productos = data.productos
                }
                await this.delay(busqueda)
            }else if(query.length === 0){
                this.listado_productos = []
            }
        },
        fun_estado(state){
            switch (state){
            case 1:
                return 'Sin Confirmar'
            case 2:
                return 'Confirmado'
            case 201:
                return 'Alistado'
            case 202:
                return 'Empacado'
            case 3:
                return 'Enviado'
            case 4:
                return 'Entregado'
            case 25:
                return 'Rechazado'
            case 26:
                return 'Cancelado Vendedor'
            case 27:
                return 'Cancelado Admin'
            default:

            }
        }
    }
}
</script>
